import React, { useRef } from 'react'
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import "../css/testimonial.css"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import bgimage from "../Assets/section-bgimage2.png"
import profile from "../Assets/profile.png"
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import { GoStarFill } from "react-icons/go";

const Testimonial = () => {
    const sliderRef=useRef(null);

    const handlePrev =()=>{
        sliderRef.current.swiper.slidePrev();
    }
    const handleNext =()=>{
        sliderRef.current.swiper.slideNext();
    }
    return (
        <div className='testimonial'>
            <div className='client-info'>
                <h2>Client Says</h2>
                <p>What our client says about us</p>
                <div>
                    <span onClick={handlePrev}><MdArrowBackIos /></span>
                    <span onClick={handleNext}><MdArrowForwardIos /></span>
                </div>
            </div>
            <div className='testimonial-card-cont' style={{backgroundImage:`url(${bgimage})`}}>
                <div className='testimonial-swiper'>
                    <Swiper
                       
                        slidesPerView={3}
                        ref={sliderRef}
                        spaceBetween={30}
                        autoplay={{ delay: 5000 }}
                        modules={[Pagination,Autoplay]}
                        breakpoints={{
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 2,
                              spaceBetween: 40,
                            },
                            1024: {
                              slidesPerView: 3,
                              spaceBetween: 50,
                            },
                          }}
                        className="mySwiperTest"
                    >
                        <SwiperSlide className='slider-slider'>
                            <div className='testimonial-clients'>
                               
                                <img src={profile}/>
                                <h5>ANAND</h5>
                                <div className='ratings'> 
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span>
                                <span><GoStarFill/></span></div>
                                <p>Working with Lithi Infotech proved to be an exceptional experience. Their techies exhibited an unparalleled level of professionalism, consistently surpassing expectations.</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className='slider-slider'>
                        <div className='testimonial-clients'>
                               
                               <img src={profile}/>
                               <h5>ARUN KUMAR N</h5>
                               <div className='ratings'> 
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span></div>
                               <p>It was a privilege to collaborate with such a dedicated and capable team, and their contributions undoubtedly elevated our project to new heights.</p>
                           </div>
                        </SwiperSlide>
                        <SwiperSlide className='slider-slider'>
                        <div className='testimonial-clients'>
                               
                               <img src={profile}/>
                               <h5>SARAVANAN J</h5>
                               <div className='ratings'> 
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span></div>
                               <p>Collaborating with Lithi Infotech was an absolute game-changer for me professionally. Their team's prowess in both content creation and service delivery was truly unmatched. </p>
                           </div>
                        </SwiperSlide>
                        <SwiperSlide className='slider-slider'>
                        <div className='testimonial-clients'>
                               
                               <img src={profile}/>
                               <h5>SAMPATH</h5>
                               <div className='ratings'> 
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span></div>
                               <p> Working with Lithi Infotech was not just a positive experience; it was a transformative one that significantly elevated my professional trajectory.</p>
                           </div>
                        </SwiperSlide>
                        <SwiperSlide className='slider-slider'>
                        <div className='testimonial-clients'>
                               
                               <img src={profile}/>
                               <h5>RAJA PANDI</h5>
                               <div className='ratings'> 
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span>
                               <span><GoStarFill/></span></div>
                               <p>The professionalism exhibited by their team set a benchmark for excellence, ensuring that every interaction was conducted with utmost precision and efficiency</p>
                           </div>
                        </SwiperSlide>
                        
                    </Swiper>
            
            </div>
        </div>
    
    </div >
  )
}

export default Testimonial