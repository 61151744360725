import { createSlice } from "@reduxjs/toolkit";
import blogImage from '../../Assets/blog/blog.jpg'


const initialState = [
    {
    id:1,img:blogImage,date:"18/02/2024",name:"VinothKumar P",
    title:"Leveraging AWS for Seamless Hosting Solutions",creation:"CLOUD",
    description:`Start with a captivating hook to grab the reader's attention. You could begin with a statistic or a compelling question related to scalability challenges in web hosting.Introduce the significance of scalability in modern web hosting and how AWS (Amazon Web Services) serves as a powerful solution.
   <br />
   <br />

    <h3>1. Understanding Scalability </h3>
    Define scalability in the context of web hosting and explain its importance for businesses of all sizes.
    Discuss the challenges of traditional hosting solutions when it comes to handling spikes in traffic and sudden resource demands.
    <br />

    <br />
    <h3> 2. Introducing AWS: </h3>
    Provide a brief overview of AWS, highlighting its position as a leading cloud services provider.
    Mention key AWS services relevant to scalability and hosting, such as EC2 (Elastic Compute Cloud), S3 (Simple Storage Service), and Auto Scaling.
    <br />

    <br />
    <h3>3. Leveraging AWS for Scalability:  </h3>
    Explain how AWS's scalable infrastructure allows businesses to dynamically adjust resources based on demand.
    Discuss the concept of elasticity and how AWS enables automatic scaling up or down of resources.
    Provide examples of companies that have successfully utilized AWS for scalable hosting solutions, citing specific case studies if available.
    <br />
    <br />

     <h3>4. Best Practices for Scalability on AWS: </h3>
    Offer practical tips and strategies for optimizing scalability on AWS, such as using load balancing, employing caching mechanisms, and designing for fault tolerance.
    Emphasize the importance of monitoring and analytics in identifying scalability bottlenecks and optimizing resource allocation.
    <br />
    <br />

    <h3> 5. Cost Considerations: </h3>
    Address the cost implications of scaling on AWS and how businesses can optimize costs while ensuring scalability.
    Discuss AWS pricing models, reserved instances, and cost management tools available to users.
    <br />
    <br />
    <h3> 6. Security and Compliance: </h3>
    Touch upon the importance of security and compliance considerations when scaling on AWS.
    Highlight AWS's robust security features and compliance certifications, reassuring readers about the safety of their data and applications.
    <br />
    <br />
    <h3> Conclusion: </h3>
    Summarize the key points discussed in the blog post, reaffirming the benefits of leveraging AWS for scalability and hosting.
    Encourage readers to explore AWS further and consider its suitability for their own hosting needs.
    Invite them to engage with your company for additional assistance or consultation on AWS implementation.`},
    {id:2,img:blogImage,date:"18/02/2024",name:"Admin",title:"blog Title 2",creation:"Technology",description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat voluptatum minima delectus iusto, veniam quo dolore sit incidunt exercitationem sed? Explicabo modi veniam ipsam magni optio, nihil reiciendis iusto earum.Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat voluptatum minima delectus iusto, veniam quo dolore sit incidunt exercitationem sed? Explicabo modi veniam ipsam magni optio, nihil reiciendis iusto earum/Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat voluptatum minima delectus iusto, veniam quo dolore sit incidunt exercitationem sed? Explicabo modi veniam ipsam magni optio, nihil reiciendis iusto earum"},
    {id:3,img:blogImage,date:"18/02/2024",name:"name",title:"blog title 3",creation:"Technology",description:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Placeat voluptatum minima delectus iusto, veniam quo dolore sit incidunt exercitationem sed? Explicabo modi veniam ipsam magni optio, nihil reiciendis iusto earum."}
]

const blogSlice = createSlice({
   name: 'blog',
   initialState,
   reducers:{},
})

export const selectAll =(state)=>state.blog;
export default blogSlice.reducer;