import React from 'react'
import "../css/footer.css"

const Footer = () => {
  return (
    <div className='footer'>
        Copyright © | 2024 All Rights Reserved | Designed By Lithi Infotech
    </div>
  )
}

export default Footer